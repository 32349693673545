<template>
    <v-app>

        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-state="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <h4>Print Notification</h4>
                            </div>

                            <div class="col-12">
                                <div class="">
                                    <table class="table table-stripe">
                                        <thead>
                                        <th>Title</th>
                                        <th>type</th>
                                        <th>status</th>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(item, index) of notifications" :key="index">
                                            <td>        <a
                                                    @click.prevent="viewNotification(item)"
                                                    href="#"
                                                    class="text-dark text-hover-primary mb-1 font-size-lg"
                                            >
                                                {{ item.title }}
                                            </a></td>
                                            <td>{{formatType(item.type)}}</td>
                                            <td>
                                                <span
                                                        class="badge text-white"
                                                        v-bind:class="item.status=='pending' ? 'warning' : item.status=='ready' ? 'primary': 'success'"
                                                >{{item.status}}</span>
                                                </td>

                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="ml-3 mr-2">-->

<!--            <div v-for="(item, i) in notifications" :key="i">-->
<!--                &lt;!&ndash;begin::Item&ndash;&gt;-->
<!--                <div class="d-flex align-items-center mb-6" v-bind:key="i" :class="{ 'read': item.is_viewed }">-->
<!--                    &lt;!&ndash;begin::Symbol&ndash;&gt;-->
<!--                    <div-->
<!--                            class="symbol symbol-40 mr-5"-->
<!--                            v-bind:class="`symbol-light-${item.status=='pending' ? 'warning' : item.status=='ready' ? 'primary': 'success' }`"-->
<!--                    >-->
<!--                <span class="symbol-label">-->
<!--                  <span-->
<!--                          class="svg-icon svg-icon-lg"-->
<!--                          v-bind:class="`symbol-light-${item.status=='pending' ? 'warning' : item.status=='ready' ? 'primary': 'success' }`"-->
<!--                  >-->
<!--                    &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
<!--                                         <i class="fas fa-print"></i>-->
<!--                      &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                  </span>-->
<!--                </span>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;end::Symbol&ndash;&gt;-->
<!--                    &lt;!&ndash;begin::Text&ndash;&gt;-->
<!--                    <div class="d-flex flex-column font-weight-bold">-->
<!--                        <a-->
<!--                                @click.prevent="viewNotification(item)"-->
<!--                                href="#"-->
<!--                                class="text-dark text-hover-primary mb-1 font-size-lg"-->
<!--                        >-->
<!--                            {{ item.title }}-->
<!--                        </a>-->
<!--                        <span class="text-muted">-->
<!--                  {{ item.status }}-->
<!--                </span>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;end::Text&ndash;&gt;-->
<!--                </div>-->
<!--                &lt;!&ndash;end::Item&ndash;&gt;-->
<!--            </div>-->

<!--        </div>-->

    </v-app>
</template>

<script>
    import NotificationService from "@/core/services/notification/NotificationService";
    import {API_URL} from "../../../common/config";

    const notificationService = new NotificationService();
    export default {
        name: "printNotification",
        data() {
            return {
                notifications: []
            }
        },
        mounted() {
            this.getNotifications()
        },
        methods: {
            getNotifications() {
                notificationService.getAllByUser(this.currentUser.id).then((response) => {
                    this.notifications = response.data.data;
                })
            },
            formatType(type) {
                    return type.split('-').join(' ').toUpperCase();
            },
            viewNotification(notification) {
                if (notification.status != 'pending') {
                    this.getNotifications();
                    window.open(`${API_URL}notification/view/${notification.id}`, "_blank");
                } else {
                    notification.is_viewed = true;
                    notificationService.update(notification.id, notification).then((res) => {
                        this.getNotifications();
                    }).catch((err) => {
                        this.getNotifications();
                    })
                }
            },
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        }
    }
</script>

<style scoped>

</style>